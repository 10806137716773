@import url('https://fonts.googleapis.com/css2?family=Poppins:ital@0;1&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  background-color: #fafff0;
  font-family: 'Poppins', sans-serif;
  font-weight: 800;
}
  /* background: linear-gradient(to bottom, #00374b, #20242d); */

span,
.primary-color {
  background-image: linear-gradient(to right, #dd32f3, #ce90cb);
  -webkit-background-clip: text;
  color: transparent;
}

.button-bg-primary-color {
  background-image: linear-gradient(to right, #6b2b72, #a5079d);
}

